.black-icon-container-height{
    height:12rem
}

.text-center{
    text-align: center;
}

.border-right{
    border-right: 1px solid white;
}

.black-container{
    text-align: center;
    background-color: #000000;
    .per-container{
        display: flex;
        justify-content:center;
        align-items: center;
        height: 12rem;
        @media (max-width : 450px){
            border-right: none;
            border-bottom: 1px solid white;
            margin-top: 2rem;
        }
        // REMOVE 4th BORDER-RIGHT 
        border-right: 1px solid white;
        
        .item-container{
            display: flex;
            flex-direction: column;
        }
    }
    :nth-child(1) > :nth-child(4) > :nth-child(1){border-right: none}
}

.carousal-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}


.profile-image.MuiSvgIcon-root{
    font-size: 5rem;
}
.landingPage{
    .sticky-navbar-container{
        width: 350px;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // width: 50%;
        position: fixed;
        z-index: 300;
        min-height: 70px;
        left: 40%;
        .navbar-flex{
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.6);
            height: 100%;
            min-height: 70px;
            border-radius: 15px;
            box-shadow: 20px;
            a{
                text-decoration: none;
                color: black;
                &:hover{
                    color: var(--purple-font);
                }
            }
            div{
                cursor: pointer;
            }
            div:hover{
                color:rgb(173,73,112);
            }
            &>:nth-child(2){
                // background-color: red;
            }
            &>:nth-child(2) :nth-child(2){
                opacity: 0;
                position: absolute;
                visibility: hidden;
                left: 20px;
                top: 100px;
            }
            &>:nth-child(2):hover{
                &>:nth-child(2){
                    opacity: 1;
                    visibility: visible;
                    width: 700px;
                    color: black;
                    padding: 32px;
                    border-radius: 10px;
                    background-color: white;
                    transition: all 0.25s ease;
                    line-height: 30px;
                    // font-size: 16px;
                    .purple{
                        color:#ad4970;
                        font-size: 20px;
                        font-style:italic;
                    }
                    // background-color: red;
                }
            }
            &>:nth-child(3){
    
            }
        }
    }
}

