html * {
  font-family: 'Jost';
}

body {
  font-family: 'Jost';
}


//---------------- ALL GLOBAL VARIABLES ---------------------//
:root {
  --purple:rgb(119,53,98);
  --purple-font:#AD4970;
  --red:rgb(233,56,72);
  --light-grey-font:#A0AEC0;
}

// ---------------- SETTING UP MARGIN AND PADDING FOR RANGE OF 1-100 WHERE 1 IS 0.5rem -------------------//
$auto: auto;
$directions: 't', 'b', 'l', 'r';
$margin-property: ('t': margin-top, 'b': margin-bottom, 'l': margin-left, 'r': margin-right);
$padding-property: ('t': padding-top, 'b': padding-bottom, 'l': padding-left, 'r': padding-right);
$max-size: 100;

@for $size from 1 through $max-size {

  @each $direction in $directions {

      .m#{$direction}-#{$size} {
        @if $size == $auto {
          #{map-get($margin-property, $direction)}: $auto !important;
        } @else {
          #{map-get($margin-property, $direction)}: #{$size * 0.5}rem !important;
        }
      }

      .p#{$direction}-#{$size} {
        @if $size == $auto {
          #{map-get($padding-property, $direction)}: $auto !important;
        } @else {
          #{map-get($padding-property, $direction)}: #{$size * 0.5}rem !important;
        }
      }
  }
  
  .m-#{$size}, .p-#{$size}{
    margin:#{$size * 0.5 rem} !important;
    padding:#{$size * 0.5rem}!important;
  }

}


//----------------------ALL FLEX PROPERTIES -----------------------//
.flex {
  display: flex;
  &.column {flex-direction: column;}
  &.wrap{flex-wrap: wrap;}
  &.jc-flex-start { justify-content: flex-start;}
  &.jc-flex-end { justify-content: flex-end;}
  &.jc-center { justify-content: center;}
  &.jc-space-between { justify-content: space-between;}
  &.jc-space-evenly { justify-content: space-evenly;}
  &.jc-space-around { justify-content: space-around;}

  &.ai-flex-start { align-items: flex-start;}
  &.ai-flex-end { align-items: flex-end;}
  &.ai-center { align-items: center;}
  &.ai-stretch { align-items: stretch;}
  &.ai-baseline { align-items: baseline;}

}

.bold{
  font-weight: bold;
}

.small-button.MuiButtonBase-root {
    height:2rem;
    font-size:0.7rem;
}
.nav-contianer {
  --bs-nav-link-padding-x: 1rem !important;
  --bs-nav-link-padding-y: 0rem !important;
}

.app-bar1 {

  background: linear-gradient(180deg, #D9374B 0%, #773562 70.83%);
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  height: 5rem;
}

.app-bar2 {

  background: linear-gradient(180deg, #D9374B 0%, #c22f40 70.83%);
  /* box-shadow: 0px 2.5px 2.5px rgba(0, 0, 0, 0.01); */
  height: 5rem;
}

.a-t {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
}

.a-t2 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rm;
  color: #FFFFFF;
  text-align: start;
}

.total-usage {
  padding: 40px 35px 21px;
}

.d-t1-c {
  color: #F2F2F2
}

.d-t2-c {
  color: #F2F2F2;
  font-size: 2.25em
}

.request {
  padding: 0px 30px 0px;

}

.request-title {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 22px;
  text-align: start;
  text-transform: uppercase;
  left: 426px;
}

.request-card {
  padding-right: 10px;
  height: 170px;
  background: #FFFFFF;
  box-shadow: 5px 5.5px 9.5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;


}

.req-card-txt {

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #454545;
}

.bg {
  background-color: aqua;
}

.bg1 {
  background-color: rgb(62, 170, 91);
}

.d-img {
  width: 40px;
  padding-bottom: 10px;
}



.gfg {
  filter: brightness(0) invert(1);
}

.sidebar-bg {
  background-color: #FFFFFF;
  margin: 0px 0px 0px;
}

.sidebar-n-bg {
  background-color: #E8E9EA;
  margin: 0px 5px 0px;

}



/* --------home----------- */
.nav-contianer {
  --bs-nav-link-padding-x: 1rem !important;
  --bs-nav-link-padding-y: 0rem !important;
}

.b-vr {
  border-left: 1px solid #FFFFFF;
}

@media screen and (max-width: 990px) {
  .b-vr {
    border-left: 0px solid #FFFFFF;
  }
}

.a-t {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
}

.a-t2 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rm;
  color: #FFFFFF;
  text-align: start;
}

.total-usage {

  padding: 40px 35px 21px;

}

.d-t1-c {
  color: #F2F2F2
}

.d-t2-c {
  color: #F2F2F2;
  font-size: 2.25em
}

.request {
  padding: 0px 30px 0px;

}

.request-title {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 22px;
  text-align: start;
  text-transform: uppercase;
  left: 426px;
}

.request-card {
  padding-right: 10px;
  height: 170px;
  background: #FFFFFF;
  box-shadow: 5px 5.5px 9.5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;


}

.req-card-txt {

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #454545;
}

.d-img {
  width: 40px;
  padding-bottom: 10px;
}

.dashboard-header {
  width: 100%;
  height: 20rem;
  left: 374px;
  top: 0px;

  background: linear-gradient(180deg, #D9374B 0%, #773562 70.83%);
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
}

.page-item .page-link {
  z-index: 1;
  color: #000 !important;
  background: #fff !important;
  border: 1px solid #AD4970;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #AD4970 !important;

}
.top-bar
{
  color:#FFFFFF !important;
  font-weight: 400;
  font-size: 12px;
}
.css-1dimb5e-singleValue
{
  color:#000000 !important;
  font-weight: 700;
  font-size: 12px;
}
.css-1jqq78o-placeholder{
  color:#000000 !important;
  font-weight: 700;
  font-size: 12px;
}

searchinput {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ada9a9;
  padding: 1% 6%;
  margin: 1% 1%;
  background-image: url(https://www.pngarea.com/pngm/109/1164446_searchicon-png-search-icon-png-download.png);
  background-size: contain;
  background-repeat: no-repeat;
  outline: 0;
  background-position: 12px;
  background-size: 16px;
}


form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  width: 260px;
  border: 1px solid transparent;
  display: block;
  padding: 9px 4px 9px 40px;
  background: #F4F8F9 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  outline:none ;

}


.bottomPage {
  display: flex;
  width: 100%;
  margin-top: 4px;
  padding: 10px 5px 0px;
  align-items: flex-end;
  justify-content: end !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C4C4C4;
  opacity: 1;
  /* Firefox */
  font-size: 12px;
}

.table-bg {
  background: #F4F8F9;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}


.table-header {

  background: #F4F8F9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #DCDCDC;

}

.table-row {
  color: #858585;
  background: #F4F8F9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.table-text {
  /* left: 445px;
top: 376px;
padding: 18px; */

}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  text-align: left;
  padding: 25px 30px 15px;
}

td {
  text-align: left;
  padding: 5px 30px 5px;
}

.menu-td {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding: 15px 0px 15px;
  color: #000;
}
.menu-td:not(:last-child) {
  border-bottom: 1px solid #DCDCDC;
}

.menu-td:hover {
  color: #AD4970;
}

tr:not(:last-child) {
  border-bottom: 1px solid #DCDCDC;
}

.activestatus {
  color: #04BB00;
  margin: 0%;
  padding: 0%;
}

.inactivestatus {
  color: red;
  padding: 0%;
  margin: 0%;
}

.custombutton {
  width: 231px;
  font-weight: 700;
  font-size: 12px;
  background: #E93848;
  border: none;
  color: white;
  padding: 13px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
}

.profile-button {

  width: 135px;
  height: 137px;
  border: none;
  background: linear-gradient(180deg, #E93848 0%, #843560 100%);
  color: #FFFFFF;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.inprofile-button {

  width: 135px;
  height: 137px;
  border: none;
  background: #E2E8F0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.center {

  text-align: center;
}

.center-box {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.flex-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  align-items: center;
  justify-content: center;
}

.common-bg {
  background: linear-gradient(180deg, #E93848 0%, #843560 100%);
}

.tran-btn {
  width: 135px;

  background: transparent;
  border: none;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
}
.inactive-tran-btn {
  width: 135px;

  background: transparent;
  border: none;
  color: #AD4970;
  font-weight: 600;
  font-size: 12px;
}

.dot-tran-btn {
  width: auto;
  margin-bottom: 10px;
  background: transparent;
  border: none;
  color: #858585;
  font-weight: 700;
  font-size: 25px;
  text-align: left;
  /* margin: 0px;
  padding: 0px; */
}


.profile_full-name {
  font-weight: 700;
  font-size: 18px;
  color: #AD4970;
}

.profile-email {
  font-weight: 500;
  font-size: 12px;
  color: #7A7979;
  line-height: 140%;


}

.profile-title {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.profile-subname {
  font-weight: 500;
  font-size: 14px;
  color: #5A5A5A;
}

.th-table-name {
  font-weight: 500;
  font-size: 12px;
  color: #5A5A5A;
  text-align: start;
  padding: 15px 0px;
}

.th-table-value {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  text-align: end;
  padding: 2px 15px;
}

.profile-status {


  color: #04BB00;
}

.profile-inactive {

  color: #E93848;
}

.profile-editor {
  width: 100%;
  padding: 2px 0px;
  margin: 5px 0;
  box-sizing: border-box;
  border: none;
  font-weight: 700;
  font-size: 12px;
  border-bottom: 0.5px solid #A29C9C;
  color: #0A0A0A;

}

.profile-editor:focus {
  outline: none;
}

.content-center {
  /* text-align: center;
  align-items: center;
  justify-content: center;
  display: flex; */

  position: absolute;
  top: 35%;
  left: 25%;
  right: 5%;
  margin: -25px 0 0 -25px;
}

/* -------------drop down---------  */
.tb-dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.tb-dropdown {
  position: relative;
  display: inline-block;
}

.tb-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.tb-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.tb-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.tb-dropdown:hover .tb-dropdown-content {
  display: block;
}

.tb-dropdown:hover .tb-dropbtn {
  background-color: #3e8e41;
}
.d-title{
  font-weight: 700;
font-size: 18px;
line-height: 140%;
/* or 25px */

text-transform: uppercase;

color: #E93848;
}

.d-label-name{
  font-weight: 600;
font-size: 14px;
line-height: 140%;
padding-left: 10px;
/* or 25px */

text-transform: uppercase;

color: #000000;
}



input.inputfield {
  width: 100%;
  border: 1px solid transparent;
  display: block;
  font-weight: 600;
  padding: 15px 0px 15px 20px;
  background: #F4F8F9;
  border-radius: 5px;
  margin-top: 10px;
  color:#5A5A5A ;
  outline:none ;
  
}

.textarea {
  width: 100%;
  resize: none;
  border: 1px solid transparent;
  display: block;
  font-weight: 600;
  padding: 15px 0px 15px 20px;
  background: #F4F8F9;
  border-radius: 5px;
  margin-top: 10px;
  color:#5A5A5A ;
  outline:none ;
}

/* Tab bar */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #AD4970 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected:hover{
  color: #AD4970 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover{
  color: gray !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  color: #C4C4C4 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #AD4970 !important;
}

.searchDrop {
  width: 100vh;
}
.dropdown-container {
  width: 100vh;
}


.loader-container {
  width: 100% ;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
}
.loader-color
{
  color:linear-gradient(180deg, #E93848 0%, #843560 100%); ;

}


