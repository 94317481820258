.action-dialog .MuiDialogTitle-root,.action-dialog .MuiDialogContent-root{
    padding-top: 5px;
    padding-bottom: 5px;
}

.card-icon-button.MuiButton-root{
    border-radius: 15px;
    min-height: 135px;
    min-width: 200px;
    .container{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        .image{
            margin-bottom: 0.5rem;
            .MuiSvgIcon-root{
                font-size: 2rem;
            }
        }
        .text{
            font-weight: 600;
        }
    }
}