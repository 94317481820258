.errorMessage {
  font-size: 12px;
  color: #e93848;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  margin-top: 0;
  overflow: hidden;
  display: none;
}

.errorMessage.visible {
  opacity: 1;
  margin-top: 10px;
  display: inline;
  animation: fadeOut 2s ease-in-out forwards 2s;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    margin-top: 0;
  }
}