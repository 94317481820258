
// @import url(../../index.scss);

//THIS IS THE FLOATING NAVBAR CONTAINER WHICH INCLUDES LOGO AND LINKS
.floating-navbar-container{
    // THIS IS TO FORCE THE TEXTFIELDS TO BE UNDER THE FLOATING NAVBAR WHEN IT SCROLL
    // .thick-container {
    //   background-color: white;
    //   position: fixed;
    //   width: 55%;
    //   height: 100px;
    //   z-index: 300;
    // }

    //LOGO TO BE FLOATING AND BE TO THE LEFT
    .navbar-floating-img-container {
      position: fixed;
      z-index: 300;
      min-height: 70px;
      margin-top: 35px;
      left: 6%;
    }

    //CONTAINER OF ALL THE LINKS
    // .navbar-floating-content-container{
    //     width: 50%;
    //     position: fixed;
    //     z-index: 300;
    //     min-height: 70px;
    //     left: 20%;
    //     border-radius: 15px;
    //     margin-top: 25px;
    //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        // POSITIONING OF ALL THE LINKS
    //     .navbar-flex{
    //         display: flex;
    //         justify-content: space-around;
    //         align-items: center;
    //         background-color: rgba(255, 255, 255, 0.6);
    //         height: 100%;
    //         min-height: 70px;
    //         border-radius: 15px;
    //         box-shadow: 20px;
    //         a{
    //             text-decoration: none;
    //             color: black;
    //             &:hover{
    //                 color: var(--purple-font);
    //             }
    //         }
    //         div{
    //             cursor: pointer;
    //         }
    //         div:hover{
    //             color:rgb(173,73,112);
    //         }
    //         button{
    //             background-color: black;
    //             color: white;
    //             border-radius: 4px;
    //             width: 146px;
    //             height: 41px;
    //             border: none;
    //         }
    //         button:hover{
    //             box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    //         }
    //     }
        
    // }
    
}
.sticky-navbar-container {
    width: 550px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // width: 50%;
    position: fixed;
    z-index: 300;
    min-height: 70px;
    left: 40%;
    top: 20px;
    .navbar-flex {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6);
        height: 100%;
        min-height: 70px;
        border-radius: 15px;
        box-shadow: 20px;

        a {
            text-decoration: none;
            color: black;

            &:hover {
                color: var(--purple-font);
            }
        }

        div {
            cursor: pointer;
        }

        div:hover {
            color: rgb(173, 73, 112);
        }

        &>:nth-child(2) {
            // background-color: red;
        }

        &>:nth-child(2) :nth-child(2) {
            opacity: 0;
            position: absolute;
            visibility: hidden;
            left: 20px;
            top: 100px;
        }

        &>:nth-child(2):hover {
            &>:nth-child(2) {
                opacity: 1;
                visibility: visible;
                width: 700px;
                color: black;
                padding: 32px;
                border-radius: 10px;
                background-color: white;
                transition: all 0.25s ease;
                line-height: 30px;

                // font-size: 16px;
                .purple {
                    color: #ad4970;
                    font-size: 20px;
                    font-style: italic;
                }

                // background-color: red;
            }
        }

        button {
            background-color: black;
            color: white;
            border-radius: 4px;
            width: 146px;
            height: 41px;
            border: none;
        }

        button:hover {
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
        }

        &>:nth-child(3) {}
    }
}
.login{
    height: 100vh;
    .left-side-container{

        //HAS TWO COMPONENET ONE BELOW ANOTHER 1-LOGIN FORM 2-FOOTER
        @media (max-width:600px){
            width: 100%;
            margin-top: 0;
        }
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 8rem;

        //LOGIN FORM
        .form-container{
            //CONTAINER TO KEEP THE FORM IN THE CENTER
            @media (max-width:600px){
                width: 100%;
                margin-left: 0;
            }
            width: 60%;
            .login-form{
                display: flex;
                flex-direction: column;
                justify-content: center;

                //WHEN IN MOBILE VIEW SHOW LOGO AND BUTTON
                .mobile-logo-sign-up{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 1rem;
                    button{
                        background-color: black;
                        color: white;
                        border-radius: 4px;
                        width: 90px;
                        height: 35px;
                        border: none;
                    }
                    img{
                        height: 35px;
                        margin: auto;
                    }
                }

                .three-buttons {
                    display: flex;
                    justify-content: center;
                    height: 43px;
                    margin-bottom: 3rem;
    
                    @media (max-width:600px){
                        :nth-child(2){
                            // background-color: red;
                            width: 200px;
                        }
                    }
                    button{
                        @media (max-width:600px){
                            width:33%;
                        }
                        width: 174px;
                        border-radius: 4px;
                        border: none;
                        margin-right: 0.4rem;
                        &.active{
                            background-image: linear-gradient(to bottom, var(--red) , var(--purple));
                            color: white;
                        }
    
                        &.inactive{
                            color: var(--purple-font);
                        }
                    }
                }
    
                .welcome{
                    @media (max-width:600px){
                        font-size: 1.5rem;
                    }
                    display: flex;
                    justify-content: center;
                    color: var(--purple-font);
                    font-weight: bold;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
                .enter-details{
                    display: flex;
                    justify-content: center;
                    color: var(--light-grey-font);
                    font-size: 1.2rem;
                    margin-bottom: 2rem;
                    @media (max-width:600px){
                        font-size: 1rem;
                    }
                }
                .textfields{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .smaller-container{
                        width: 60%;
                        @media (max-width:600px){
                            width: 80%;
                        }
                        .remember-me-container{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .checkbox-container{
                                display: flex;
                                align-items: center;
                                .MuiSvgIcon-root path{
                                    color:var(--purple-font)
                                }
                            }
                        }
                        .signup-register-button{
                            width: 100%;
                            button{
                                height: 40px;
                                width: 100%;
                                background-image: linear-gradient(to bottom, var(--red) , var(--purple));
                                color: white;
                            }
                        }
                    }
                }
            }
        }
        .register-container{
            width: 75%;

            //FOR MOBILE VIEW SHOW LOGO AND SIGNUP/LOGIN BUTTON
            .mobile-logo-sign-up{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 1rem;
                button{
                    background-color: black;
                    color: white;
                    border-radius: 4px;
                    width: 90px;
                    height: 35px;
                    border: none;
                }
                img{
                    height: 35px;
                    margin: auto;
                }
            }

            .welcome{
                @media (max-width:600px){
                    font-size: 1.5rem;
                }
                display: flex;
                // justify-content: center;
                color: var(--purple-font);
                font-weight: bold;
                font-size: 2rem;
                margin-bottom: 1rem;
            }

            .first-row{
                display: flex;
                justify-content: space-between;

                & > :first-child{
                    flex:0.49
                }
                :nth-child(2){
                    flex:0.49;
                }
            }
            .second-row{
                display: flex;
                justify-content: space-between;
                & > :first-child{
                    flex:0.49;
                }
                :nth-child(2){
                    flex:0.49;
                }
            }
            .third-row{
                display: flex;
                justify-content: space-between;

                & > :first-child{
                    @media (max-width:600px){
                        font-size:14px;
                    }
                    flex:0.49;
                }
                :nth-child(2){
                    flex:0.49;
                }
            }
            .fourth-row{
                display: flex;
                & > :first-child{
                    flex:1;
                }
            }
            .fifth-row{
                display: flex;
                justify-content: space-between;
                & > :first-child{
                    flex:0.49;
                }
                :nth-child(2){
                    flex:0.49;
                }
            }
            .sixth-row{
                display: flex;
                justify-content: space-between;
                & > :first-child{
                    width: 49%;
                    & > :first-child{
                        margin-bottom: 12px;
                    }
                }
                .input-container{
                    width: 49%;
                    .inputy{
                        display: flex;
                        .fileInput{
                            width: 100%;
                        }
                        input[type=file]::file-selector-button {
                            height: 40px;
                            border: 1px solid lightgray;
                            padding: .2em .4em;
                            border-radius: 4px;
                            background-color: white;
                          }
                          
                          input[type=file]::file-selector-button:hover {
                            border: 1px solid rgb(177, 177, 177);
                            border-radius: 4px;
        
                          }
                    }
                    .redError{
                        & >:nth-child(1){
                            color: rgb(199, 3, 3);
                            font-size: 12px;
                            margin-top: 5px;
                        }
                    }
                }

            }
            .seventh-row{
                display: flex;
                justify-content: space-between;
                :first-child{
                    flex:0.49;
                }
                :nth-child(2){
                    flex:0.49;
                    
                }
            }
            .eigth-row{

            }
            .ninth-row{
                display: flex;
                justify-content:center;
                    button{
                        border-radius: 4px;
                        border: none;
                        height: 40px;
                        width: 48%;
                        background-image: linear-gradient(to bottom, var(--red) , var(--purple));
                        color: white;
                    }
            }
        }
        //FOOTER
        .login-footer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin: 1rem;
            .left-footer{
                .purple{
                    color: var(--purple-font);
                }
            }
            .right-footer{
                display: flex;
                font-size: 0.8rem;
            }
        }
    }
    .right-side-container{
        //EVEN IF YOU PUT MEDIA ABOVE THE SAME VALUE IE DISPLAY FLEX,IT WILL NOT BE OVERWRITTEN BECAUSE @media has HIGHER SPECIFICITY
        @media (max-width:600px) {
            display:none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        background-image: url('../../assets/img/layer.jpg');
        background-size: auto 100%;

        border-radius: 0px 0px 0px 30px;
        img{
            height:150px;
        }
    }
}


.button{
    width: 174px;
    border-radius: 4px;
    border: none;
    height: 43px;
}

.Active {
    background-image: linear-gradient(to bottom, rgb(233,56,72) , rgb(119,53,98));
    color: white;
    width: 174px;

}
.Inactive{
    color: rgb(173,73,112);
    width: 174px;
}
.background-img{
    background-image: url('../../assets/img/layer.jpg');
    border-radius: 0px 0px 0px 30px;
}

.MuiBox-root .mobile-about-us-font *{
    font-size: 14px;
}

