.whatwedoContainer{
    // background: linear-gradient(0, rgb(214, 195, 199) 11.4%, rgb(164, 64, 88) 70.2%);
    // height: 100vh;
    &__header{
        display: flex;
        &__logo{
            margin-left: 10px;
            margin-top: 20px;
        }
        &__floater{
            .sticky-navbar-container{
                width: 350px;
                border-radius: 15px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                height: 50px;
                // width: 50%;
                position:fixed;
                z-index: 500;
                min-height: 70px;
                top: 20px;
                // left: 100%;
                .navbar-flex{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.6);
                    height: 100%;
                    min-height: 70px;
                    border-radius: 15px;
                    box-shadow: 20px;
                    a{
                        text-decoration: none;
                        color: black;
                        &:hover{
                            color: var(--purple-font);
                        }
                    }
                    div{
                        cursor: pointer;
                    }
                    div:hover{
                        color:rgb(173,73,112);
                    }
                    &>:nth-child(2){
                        // background-color: red;
                    }
                    &>:nth-child(2) :nth-child(2){
                        opacity: 0;
                        position: absolute;
                        visibility: hidden;
                        left: 20px;
                        top: 100px;
                    }
                    &>:nth-child(2):hover{
                        &>:nth-child(2){
                            opacity: 1;
                            visibility: visible;
                            width: 700px;
                            color: black;
                            padding: 32px;
                            border-radius: 10px;
                            background-color: white;
                            transition: all 0.25s ease;
                            line-height: 30px;
                            // font-size: 16px;
                            .purple{
                                color:#ad4970;
                                font-size: 20px;
                                font-style:italic;
                            }
                            // background-color: red;
                        }
                    }
                }
            }
        }
    }


    &__title{
        padding: 40px;
        display: flex;
        margin-top: 80px;
        background-color: #F5F7FB;
        height: 100%;
        &__name{
            margin-left: 120px;
            .bold{
                font-size: 50px;
                font-weight: bold;
                margin-bottom: 20px;
                // font-family:Georgia, 'Times New Roman', Times, serif;
            }
            .text{
                font-size: 18px;
            }
            width: 50%;
        }
        &__image{
            // width: 50%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            position: relative;
            top: 50px;
            left: 120px;
            img{
                filter: invert(100%);
            }
        }
    }
    &__scope{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        &__title{
            font-size: 50px;
            font-weight: bold;
            margin-bottom: 50px;
        }
        &__content{
            display: flex;
            margin-left: 100px;
            &__left{
                width: 50%;
            }
            &__right{
                width: 50%;
                font-size: 18px;
                margin-right: 20px;
            }
        }
    }
    &__need{
        background-color: #F5F7FB;
        margin-top: 50px;
        padding-top: 50px;
        padding-left: 100px;
        margin-bottom: 50px;
        padding-bottom: 10px;
        // padding: 40px;
        &__title{
            font-size: 45px;
            font-weight: bold;
        }
        &__content{
            display: flex;
            justify-content: space-between;
            &__left{
                &__iconAndText{
                    display: flex;
                    margin-top: 50px;
                    margin-bottom: 20px;
                    align-items: center;
                    &__image{
                        margin-right: 40px;
                        height: 100px;
                        width: 100px;
                        border-radius: 10px;
                        background-color: #b46b8b;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        :nth-child(1){
                            font-size: 50px;
                        }
                    }
                    &__text{
                        font-size: 30px;
                        font-weight: bold;
                        color: #b46b8b;
                        width: 200px;
                        border-radius: 10px;
                    }
                }
                &__iconAndText.selected{
                    background-color: #dcdee1;
                    transition: all 0.25s ease;
                }
            }
            &__right{
                width: 60%;
                margin-right: 60px;
                margin-bottom: 60px;
                &__table{

                }
                img{
                    width: 800px;
                }
            }
        }
        &__text{
            margin-left: 10px;
            font-size: 18px;
            margin-top: 10px;
            a{
                color: rgb(181, 59, 106);
            }
        }
    }
    &__solution{
        display: flex;
        margin-left: 100px;
        justify-content: space-between;
        margin-bottom: 50px;
        &__left{
            width: 50%;
            &__title{
                font-size: 40px;
                font-weight: bold;
            }
            &__content{
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
        &__right{
            width: 45%;
            margin-top: 40px;
        }
    }
    &__partiesCovered{
        // display: flex;
        // justify-content: center;
        // background-color: #F5F7FB;
        &__title{
            font-size: 40px;
            font-weight: bold;
            text-align: center;
        }
        &__image{
            display: flex;
            justify-content: center;

        }
    }
    &__benefits{
        background-color: #F5F7FB;
        &__title{
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 50px;
        }
        &__image{
            display: flex;
            flex-wrap: wrap;
            &__wrapper{

                &__title{
                    margin-left: 50px;
                    font-size: 40px;
                    font-weight: bold;
                }
                &>{
                    // width: 40%;
                    img{
                        width: 700px;
                        margin-left: 50px;
                        margin-bottom: 50px;
                    }
                }
            }

        }
    }
}

.wrapperTable{
    max-height: 600px;
    overflow-y: scroll;
}